<template>
    <LayoutNew>
        <h1 class="text-center">Whitelist</h1>
        <MDBCard id="WhitelistComponent" fluid>
            <MDBCardHeader class="p-4">
                <MDBBtn size="sm" color="primary" @click="createWhitelistModal = true">
                    Create new Whitelist
                </MDBBtn>
                <!-- create whitelist modal -->
                <MDBModal id="createWhitelistModal" tabindex="-1" labelledby="CreateWhitelistLabel"
                    v-model="createWhitelistModal" size="lg">
                    <MDBModalHeader class="py-3 px-4">
                        <MDBModalTitle id="CreateWhitelistLabel">
                            Create Whitelist
                        </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody class="p-4">
                        <h6>Name</h6>
                        <MDBInput class="mb-2" placeholder="Enter Whitelist Name *" type="text" v-model="whitelistName"
                            required invalidFeedback="Please Enter Whitelist Name" />
                        <h6 class="mt-4">Add Users/Channel</h6>
                        <p>
                            Enter URLs of the Users/Channel you would like to add to your
                            whitelist.
                            <br />
                            Multiple URLs must be entered on a separate line or separated by a
                            comma (max 20,000).
                            <br />
                            Valid URLs must start with either
                            <strong>http:// or https://</strong>
                        </p>
                        <p>
                            <u>If adding a large amount of Users/Channels, please allow up to 5
                                minutes to complete</u>
                        </p>
                        <MDBTextarea class="w-100" rows="4" type="textarea" placeholder="Enter Users/Channel"
                            v-model.trim="usersChannel" required />
                        <h6 class="mt-4 mb-2">Date Range</h6>
                        <div class="d-flex gap-3 mb-1">
                            <MDBDatepicker v-model="startDate" label="Select Start date" required />
                            <MDBDatepicker v-model="endDate" label="Select End date" required />
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter class="d-flex justify-content-end py-3 gap-2">
                        <MDBBtn type="button" size="sm" @click="createWhitelistModal = false">Close</MDBBtn>
                        <MDBBtn type="button" size="sm" color="primary" @click="createWhitelist"
                            :disabled="!isFormComplete">
                            Create Whitelist</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBCardHeader>
            <!-- table -->
            <MDBCardBody class="overflow-auto" ref="el">
                <MDBTable hover striped class="align-middle">
                    <thead class="table-dark">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date Range</th>
                            <th scope="col">Users/Channels</th>
                            <th scope="col">Created</th>
                            <th scope="col">Updated</th>
                            <th scope="col">Status</th>
                            <!--<th scope="col">Action</th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="row-action" v-for="(whitelist, index) in whitelistList" :key="index"
                            @click="viewWhitelist(whitelist.whitelistIdString)">
                            <td>{{ whitelist.title }}</td>
                            <td>{{ dateLimitString(whitelist.datesLimit) }}</td>
                            <td>1</td>
                            <td>{{ parseDateLong(whitelist.createdAt) }}</td>
                            <td>{{ parseDateLong(whitelist.updatedAt) }}</td>
                            <td>
                                <template v-if="whitelist.status != ''">
                                    <MDBBadge :color="statusBadge(whitelist.status)">
                                        {{ whitelist.status }}
                                    </MDBBadge>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="isLoading">
                            <td colspan="7" class="text-center">
                                <MDBSpinner />
                            </td>
                        </tr>
                    </tbody>
                </MDBTable>
            </MDBCardBody>
            <!-- toast -->
            <MDBToast v-model="toast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
                color="success" text="white" icon="fas fa-check fa-lg me-2">
                <template #title> Success </template>
                Whitelist Created Successfully
            </MDBToast>
        </MDBCard>
    </LayoutNew>
</template>

<script setup>
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBInput,
    MDBBtn,
    MDBTable,
    MDBSpinner,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBTextarea,
    MDBDatepicker,
    MDBToast,
    MDBBadge,
} from "mdb-vue-ui-kit";
import LayoutNew from "./LayoutNew.vue";
import { ref, onMounted, computed, watch } from "vue";
import { GetWhitelist } from "@/services/Whitelist/GetWhitelist";
import { PostWhitelist } from "@/services/Whitelist/PostWhitelist";
import { parseDateLong, parseDateForBackend } from "@/helpers/parseDate";
import { useInfiniteScroll } from "@vueuse/core";
import { useTitle } from "@vueuse/core";

useTitle("Copyright - Whitelist | CreatorShield");

const selectedSortBy = ref();
watch(selectedSortBy, async (newVal) => {
    whitelistList.value = [];
    if (newVal === 0) {
        whitelistList.value = await getWhitelist();
    } else {
        whitelistList.value = await getWhitelist({ status: newVal });
    }
    isLoading.value = false;
});

const whitelistList = ref([]);
const viewWhitelist = (id) => {
    console.log(id);
};

const createWhitelist = async () => {
    const formBody = {
        title: whitelistName.value,
        datesLimit: {
            startDate: parseDateForBackend(startDate.value),
            endDate: parseDateForBackend(endDate.value),
        },
        sourceUrl: usersChannel.value.replace(/\n/g, ","),
    };
    await PostWhitelist(formBody);
    createWhitelistModal.value = false;
    toast.value = true;
    whitelistList.value = await getWhitelist();
    isLoading.value = false;
};

const isLoading = ref(false);
const getWhitelist = async (params) => {
    isLoading.value = true;
    const response = await GetWhitelist({ ...params });
    return response;
};

onMounted(async () => {
    whitelistList.value = await getWhitelist();
    isLoading.value = false;
});

const dateLimitString = (dateLimitObj) => {
    if (dateLimitObj.endDate === "infinity") {
        return "Beginning of time - End of Time";
    }
    return `${parseDateLong(dateLimitObj.startDate)} - ${parseDateLong(
        dateLimitObj.endDate
    )}`;
};

const createWhitelistModal = ref(false);

const statusBadge = (status) => {
    if (status === "Active") {
        return "success";
    } else if (status === "Scheduled") {
        return "primary";
    } else {
        return "";
    }
};

const whitelistName = ref("");
const usersChannel = ref("");
const startDate = ref("");
const endDate = ref("");
const isFormComplete = computed(() => {
    return (
        whitelistName.value != "" &&
        usersChannel.value != "" &&
        startDate.value != "" &&
        endDate.value != ""
    );
});

watch(createWhitelistModal, () => {
    whitelistName.value = "";
    usersChannel.value = "";
    startDate.value = "";
    endDate.value = "";
});

const toast = ref(false);
const el = ref();
const stopScrollLoad = ref(false);

useInfiniteScroll(
    el,
    async () => {
        if (
            isLoading.value ||
            stopScrollLoad.value ||
            whitelistList.value.length === 0
        ) {
            return;
        }
        const currentArr = [...whitelistList.value];
        const lastItem = currentArr.pop();
        const response = await getWhitelist({
            lastItem: lastItem.whitelistIdString,
        });
        if (response.length === 0) {
            stopScrollLoad.value = true;
            isLoading.value = false;
            return;
        }
        whitelistList.value = whitelistList.value.concat(response);
        isLoading.value = false;
    },
    { distance: 10 }
);
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
</style>
