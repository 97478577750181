import { HttpClient } from "../HttpClient";
/**
 * * Get Whitelist
 */
export const GetWhitelist = async ({ lastItem, status }) => {
    try {
        const { data } = await HttpClient.get("api/pexwhitelists/list", {
            params: {
                ...(lastItem ? { MaxId: lastItem } : {}),
                count: 10,
                status: status
            },
        });
        return await Promise.resolve(data);
    } catch (err) {
        return Promise.reject(err);
    }
};